<template>
  <div />
</template>

<script>
import { AuthApiService } from '../../services/api/AuthApiService'

export default {
  beforeRouteEnter (to, from, next) {
    if (from.name === 'login') return next(false)
    next((vm) => {
      vm.logout()
    })
  },
  props: {
    logoutAction: {
      type: String,
      default: 'logoutGlobally'
    }
  },
  methods: {
    async logout () {
      try {
        await AuthApiService.logout()
      } catch (e) {
        console.log('Cant logout', e)
      }
      await this.$store.dispatch(this.logoutAction)
      this.$ma && this.$ma.reset()
      // dont show message if we dont want to. Pass no_message query param.
      if (!this.$route.query.no_message) {
        this.$notify({
          title: this.$t('success.logged_out_successfully'),
          type: 'success'
        })
      }

      let query = {}
      const routeQuery = this.$route.query

      if (routeQuery.redirect) {
        query.redirect = routeQuery.redirect
      }
      this.$router.push({ name: 'login', query })
    }
  }
}
</script>
